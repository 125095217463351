import Text from '@/components/Text/Text'
import { Row } from '@christiankaindl/lyts'
import Image from 'next/image'
import amigaMonitoringLogo from '@/public/assets/amiga-monitoring-logo.png'

export function MonitoringLogo ({ size }: { size?: 'default' | 'large' }) {
  const imgSize = size === 'large' ? 32 : 24
  return (
    <Row gap={0.5}>
      <Image priority alt='Amiga Monitoring product logo' width={imgSize} height={imgSize} src={amigaMonitoringLogo} />
      <Text as='span' size={size}><span style={{ fontWeight: 600 }}>AmigaAI</span><span style={{ fontWeight: 400 }}> Monitoring</span></Text>
    </Row>
  )
}
