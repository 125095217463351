import { Client } from '@notionhq/client'
import { type QueryDatabaseParameters, type BlockObjectResponse, type PageObjectResponse } from '@notionhq/client/build/src/api-endpoints'
import { isDev, nonNullable } from './utils'
import { s3 } from './backend'
import { cache } from 'react'
import { env } from '@/env'

export const blogDatabaseId = env.NOTION_DATABASE_ID
export const topicsDatabaseId = env.NOTION_TOPICS_DATABASE_ID

export const notion = new Client({
  auth: env.NOTION_API_KEY,
})

interface QueryDatabase {
  limit?: number
  databaseId?: string
  filter?: QueryDatabaseParameters['filter']
}
export const queryDatabase = cache(async ({ limit, databaseId = blogDatabaseId, filter }: QueryDatabase = {}) => {
  const response = await notion.databases.query({
    database_id: databaseId,
    sorts: [{
      property: 'Publish date',
      direction: 'descending',
    }],
    page_size: limit,
    // @ts-expect-error notion types are wrong
    filter: isDev
      ? {
          and: [
            ...(filter && ('and' in filter && Array.isArray(filter.and)) ? filter.and : [filter]),
            {
              or: [
                {
                  property: 'Status',
                  status: { equals: 'Published' },
                },
                {
                  property: 'Status',
                  status: { equals: 'Review' },
                },
                {
                  property: 'Status',
                  status: { equals: 'In Progress/Drafts' },
                },
              ],
            },
          ].filter(nonNullable),
        }
      : {
          and: [
            ...(filter && ('and' in filter && Array.isArray(filter.and)) ? filter.and : [filter]),
            {
              property: 'Status',
              status: {
                equals: 'Published',
              },
            },
          ].filter(nonNullable),
        },
  })
  return response.results as PageObjectResponse[]
})

export const getPage = cache(async (pageId: string, databaseId: string = blogDatabaseId) => {
  const response = await notion.databases.query({
    database_id: databaseId,
    filter: {
      property: 'Slug',
      rich_text: {
        equals: decodeURIComponent(pageId),
      },
    },
  })

  if (response.results[0]?.id === undefined) {
    return undefined
  }

  const [
    page,
    ...authors
  ] = await Promise.all([
    notion.pages.retrieve({ page_id: response.results[0]?.id ?? pageId }) as Promise<PageObjectResponse>,
    // @ts-expect-error notion...
    ...((response.results[0] as PageObjectResponse)?.properties.Authors.relation ?? [])?.map(async (rel) => {
      return await getAuthor(rel.id)
    }),
  ]).catch((error) => {
    console.log('error', error)
    return [undefined]
  })

  if (page === undefined) {
    return undefined
  } else if (!isDev && page.properties?.Status.type === 'status' && page.properties?.Status?.status?.name !== 'Published') {
    return undefined
  }

  // @ts-expect-error we will switch away from notion soon
  page.properties.Authors.relation = authors

  await uploadImages([
    // ...blocks,
    { ...page.properties.Image, id: page.id },
    ...(extractProperty(page.properties.Authors, 'relation') ?? []).map((author) => ({ ...author.icon, id: author.id })),
  ].filter(nonNullable))

  return page
})

export const getAuthor = cache(async function getAuthor (authorId: string) {
  const author = await notion.pages.retrieve({
    page_id: authorId,
  })

  return author
})

export const getBlocks = async (blockId) => {
  const blocks: BlockObjectResponse[] = []
  let cursor
  while (true) {
    const { results, next_cursor: nextCursor } = await notion.blocks.children.list({
      start_cursor: cursor,
      block_id: blockId,
    })
    // @ts-expect-error need better typing from our side
    blocks.push(...results)
    if (!nextCursor) {
      break
    }
    cursor = nextCursor
  }
  return blocks
}

export const getImageSrc = async (blockId: string) => {
  const block = (await notion.blocks.retrieve({ block_id: blockId })) as BlockObjectResponse
  // @ts-expect-error notion lies to us
  const image = block.image

  if (!image) {
    throw new Error('Block is not an image or image does not exist')
  }

  if (image.type === 'external') {
    return image.external.url
  }

  return image.file.url
}

export async function getPost (slug: string, databaseId?: string) {
  const page = await getPage(slug, databaseId)
  if (page === undefined) {
    return
  }

  const blocks = await getBlocks(page.id)

  // Retrieve block children for nested blocks (one level deep), for example toggle blocks
  // https://developers.notion.com/docs/working-with-page-content#reading-nested-blocks
  const childBlocks = await Promise.all(
    blocks
      .filter((block) => block.has_children)
      .map(async (block) => {
        return {
          id: block.id,
          children: await getBlocks(block.id),
        }
      }),
  )
  blocks.forEach((block) => {
    // Add child blocks if the block should contain children but none exists
    if (block.has_children && !block[block.type].children) {
      block[block.type].children = childBlocks.find(
        (x) => x.id === block.id,
      )?.children
    }
  })

  return {
    blocks,
    page,
  }
}

export function extractProperty (property: PageObjectResponse['properties'][string], type: PageObjectResponse['properties'][string]['type']) {
  if (!property) return

  return property.type === type ? property[type] : undefined
}

/**
 * Notion images expire after an hour. Use this function to upload images to S3 and get a permanent URL.
 */
export async function uploadImages (content: Array<BlockObjectResponse | PageObjectResponse['properties'][string]>) {
  // Loop over content and upload images to S3
  await Promise.all(content.map(async (block) => {
    let src
    if (block.type === 'image') {
      src = block.image.type === 'external' ? block.image.external.url : block.image.file.url
    } else if (block.type === 'files') {
      // @ts-expect-error File types are wrong
      src = block.files[0]?.file?.url
    } else if (block.type === 'file') {
      // @ts-expect-error File types are wrong
      src = block.file.url
    }

    if (!src) {
      return
    }

    const response = await fetch(src)

    await s3.putObject({
      Bucket: env.BUCKET_NAME,
      Key: `data/notion/images/${block.id}`,
      // @ts-expect-error AWS types are fucked up
      Body: await response.arrayBuffer(),
      ACL: 'public-read',
    })

    if (block[block.type]?.children) {
      console.log('calling recursivly', block[block.type].children)
      await uploadImages(block[block.type].children)
    }
  }))
}
