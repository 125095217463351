import { Box } from '@christiankaindl/lyts'
import React from 'react'
import Text from '../Text/Text'
import styles from './Spinner.module.css'

interface SpinnerProps {
  text?: string
  delay?: boolean
  size?: number
  orientation?: 'horizontal' | 'vertical'
}

export default function Spinner ({ text = undefined, size = 20, orientation = 'horizontal' }: SpinnerProps) {
  return (
    <Box gap={0.5} className={styles.spinner} orientation={orientation === 'horizontal' ? 'row' : 'column'}>
      <div className={styles['svg-wrapper']}>
        <svg height={size} width={size} stroke='currentColor' fill='none' strokeWidth='2' viewBox='0 0 24 24' strokeLinecap='round' strokeLinejoin='round' xmlns='http://www.w3.org/2000/svg'>
          <line x1='12' y1='2' x2='12' y2='6' />
          <line x1='16.24' y1='7.76' x2='19.07' y2='4.93' />
          <line x1='18' y1='12' x2='22' y2='12' />
          <line x1='16.24' y1='16.24' x2='19.07' y2='19.07' />
          <line x1='12' y1='18' x2='12' y2='22' />
          <line x1='4.93' y1='19.07' x2='7.76' y2='16.24' />
          <line x1='2' y1='12' x2='6' y2='12' />
          <line x1='4.93' y1='4.93' x2='7.76' y2='7.76' />
        </svg>
      </div>
      {text !== undefined && (
        <Text color='tertiary' size='0.9em' className={styles.text}>{text}</Text>
      )}
    </Box>
  )
}
