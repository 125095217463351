import LawIcon from '@/public/assets/law.icon.svg'
import { FlagIcon, LandmarkIcon, MegaphoneIcon, ScaleIcon, ScrollIcon } from 'lucide-react'

export const iconMappings = {
  bundesgesetze: LawIcon,
  bundesverfassungsgesetze: LawIcon,
  verordnungen: LawIcon,
  vertraege: FlagIcon,
  kundmachungen: MegaphoneIcon,
  vereinbarungen: ScrollIcon,
  geschaeftsordnungen: ScrollIcon,
  entschliessungen: ScrollIcon,
  bekanntmachungen: ScrollIcon,
  verfuegungen: ScrollIcon,
  justiz: ScaleIcon,
  institution: LandmarkIcon,
}
