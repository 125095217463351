import Text from '../Text/Text'
import { root } from './InputRow.css'

interface InputRowProps {
  label: string
  type?: string
  name?: string
  required?: boolean
  placeholder?: string
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  value?: string
}

export default function InputRow ({
  label,
  name = label,
  required,
  type = 'text',
  placeholder = ' ', // Use space so that the CSS :placeholder-shown selector works
  value,
  onChange,
}: InputRowProps) {
  return (
    <label className={root}>
      <Text size='small' as="span">{label}</Text>
      <input type={type} name={name} required={required} placeholder={placeholder} onChange={onChange} value={value} />
    </label>
  )
}
